import * as React from "react"
import SiteLayout from "../containers/sitelayout"
import Seo from "../components/seo"

const Community = () => (
  <SiteLayout>
    <Seo title="Community" />
    {/* new components here */}
  </SiteLayout>
)

export default Community
